import React from 'react';
import IconWaveTitle from "../titles/IconWaveTitle"
import iconAnchor from '../../../assets/icons/icon-anchor.svg';
import TeamMembersGrid from '../../team/TeamMembersGrid';

const WhoWeAre = () => {
    return (
        <section id="safe-harbor-who-we-are" className="no-padding-bottom">
          <div className="container small flex column gap">
            <IconWaveTitle icon={iconAnchor} iconFormat="png" title="Who We Are"/>
            <TeamMembersGrid/>
          </div>
        </section>
    )

}

export default WhoWeAre;