import React from "react";
import star from '../../assets/icons/icon-star.svg';
import quotes from '../../assets/icons/icon-quotes.svg';

const TestimonialCard = (props) => {
    var stars = props.stars ?? -1;
    return (
        <div className="flex column relative pt-5 pb-4 px-4 w-20 testimonial-card">
            <img src={quotes} alt="" className="absolute" style={{top: "-20px", left: "20px"}} />
            <p className="grey smaller">{props.title}</p>
            <div className="flex column items-end mt-4">
                <p className="purple smaller bold">- {props.author}</p>
                {
                    stars > -1 ?
                    <div className="flex start gap-small items-center mt-1">
                        {[...Array(5)].map((e, i) => <img style={{maxWidth: "18px", opacity: (stars > i) ? "1": "0.3" }} key={i} src={star} />)}
                    </div>
                    : 
                    <></>    
                }
            </div>
        </div>
    )
}

export default TestimonialCard;