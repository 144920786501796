import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import WaveTitle from "../titles/WaveTitle"
import TestimonialCard from '../../testimonial/TestimonialCard'

const Testimonials = () => {
    const { allStrapiTestimonial } = useStaticQuery(graphql`
    query {
        allStrapiTestimonial {
        nodes {
            author
            stars
            quote
        }
      }
    }
    `)

  return (
    <section id="safe-harbor-testimonials">
      <WaveTitle title="Is It Helping?" />
      <div className="container flex wrap center items-center gap-vertical">
        
        {
            allStrapiTestimonial.nodes.map((testimonial) => (
                <TestimonialCard 
                    title={testimonial.quote}
                    author={testimonial.author}
                    stars={testimonial.stars}
                />
            ))
        }



      </div>

      {/* <div className="flex center mt-5">
        <Link className="btn secondary" href="/testimonials">Read More</Link>
      </div> */}

    </section>
  )
}

export default Testimonials;