import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import monstersAbout from '../assets/images/monsters-about.png';
import aboutUs from '../assets/images/monsters5-about.png';
import iconTopDiv from '../assets/icons/icon-top-div-green.png';
import doubleBubble from '../assets/images/doublebubble.svg';
import IconWaveTitle from "../components/ui/titles/IconWaveTitle";
import Card from "../components/ui/cards/Card"
import iconCalmWater from '../assets/icons/icon-calm-water.svg';
import wavyMedium from '../assets/icons/wavy-medium.svg';
import Testimonials from "../components/ui/sections/Testimonials";
import WhoWeAre from "../components/ui/sections/WhoWeAre";

const AboutPage = () => {
  return (
    <Layout>
      <Seo seo={{ metaTitle: "Self Guided Sessions" }} />
      <main>
        <section id="safe-harbor-introduction">
            <div className="container small">
                <div className="flex wrap between items-center">
                <div className="flex column w-60">
                    <img className="w-60 mb-4" src={wavyMedium} alt="" />
                    <h1 className="grey">About Us</h1>
                </div>
                {/* <img className="w-40" src={aboutUs} alt="About Us" /> */}
                </div>
            </div>
        </section>
        <section id="safe-harbor-our-mission" className="bg-purple relative">
            <img src={iconTopDiv} className="absolute" style={{width: "150px", top: "0", left: "50%", transform: "translate(-50%, -50%)"}} />
            <div className="container small">
                <div className="flex column items-center gap">
                    <h1 className="blue text-center">Our Mission:</h1>
                    <img src={doubleBubble} className="w-25 my-5" />
                    <p className="large white text-center light">
                      To create a safe harbor for people to deepen and thrive in their relationships.
                      </p>
                </div> 
            </div>
        </section>
        {/* <section id="safe-harbor-some-history" className="no-padding-bottom">
            <div className="container small flex column gap">
                <IconWaveTitle icon={iconTopDiv} iconFormat="png" title="What's with the Monsters?"/>
                <div className="flex wrap between gap items-start">
                  <div className="w-60 flex column gap">
                    <p className="black medium">
                      Like monsters, we are all capable of very wide ranges of emotions. Each of us can act in ways that we may eventually regret. 
                      But we can also be lovable creatures with very big hearts. Our goal is to create the safe harbor that allows for these moments.
                      To teach the tecniques that will help us communicate better and to resolve conflicts in a way that will bring us closer together.
                    </p>

                    <p className="black medium">
                       We aim to offer entertaining and educational curricula 
                      with interactive learning experiences with one focus; to deepen and thrive in our relationships.
                    </p>
                  </div>
                    <Card className="w-25" icon={iconCalmWater}>
                      <p className="blue medium text-center">
                      Monsters are lovable creatures with very big hearts. We all can learn to communicate better, and to resolve conflicts in a way that will bring us closer together.
                      </p>
                    </Card>
                </div>
            </div>
        </section> */}
        <WhoWeAre />
        <Testimonials />
      </main>
    </Layout>
  )
}

export default AboutPage
