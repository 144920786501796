import { Link } from "gatsby";
import React from "react";

const Card = ({children, icon, title, link, linkTitle, className = ""}) => {
    return(
        <div className={`card flex column center relative bg-purple radius-20 py-5 px-5 ${className}`}>
            <img className="absolute" src={icon} alt="" style={{width: "80px", top: "0", left: "50%", transform: "translate(-50%, -50%)"}} />
            {
                title !== undefined &&
                <h1 className="blue text-center">{title}</h1>
            }
            {children}

            {
                link && linkTitle &&
                <Link className="btn primary absolute" to={link} style={{bottom: "0", left: "50%", transform: "translate(-50%, 50%)"}}>{linkTitle}</Link>
            }
        </div>
    )
}

export default Card;