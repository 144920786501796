import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import TeamMemberCard from "./TeamMemberCard";

const TeamMembersGrid = () => {
    const {allStrapiTeamMember} = useStaticQuery(graphql`
        query {
            allStrapiTeamMember(
                sort: {fields: sort, order: ASC}
                filter: {team_types: {elemMatch: {type: {eq: "Team"}}}}) {
                nodes {
                    ...TeamMemberCard    
                }
            }
        }
    `)

    return(
        <div className="flex wrap center gap-large">
            { 
                allStrapiTeamMember.nodes.map((teamMember, index) => (
                    <TeamMemberCard key={index} teamMember={teamMember} />
                ))
            }
        </div>
    )
}

export default TeamMembersGrid;